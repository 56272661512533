// import { useState } from 'react';
import { 
  // ChevronRight, 
  ExternalLink, Github, BadgeInfoIcon } from 'lucide-react';
// import { Camera } from 'lucide-react';
import { Link } from 'react-router-dom';

interface Project {
  id: number;
  name: string;
  excerpt: string;
  isLive: boolean;
  isPrivate: boolean;
  liveUrl?: string;
  githubUrl: string;
  technologies: string[];
  images: string[];
  description: string;
}

// const GalleryButton: React.FC<{ projectId: string | number }> = ({ projectId }) => (
//   <Link
//     to={`/project/${projectId}/gallery`}
//     className="flex items-center gap-2 px-4 py-2 bg-purple-600 hover:bg-purple-700 rounded-md transition-all duration-300 text-white transform hover:-translate-y-0.5"
//   >
//     <Camera className="w-4 h-4" />
//     <span>View Gallery</span>
//   </Link>
// );

const ProjectShowcase: React.FC = () => {
  // const [activeProject, setActiveProject] = useState<number | null>(null);

  const projects: Project[] = [
    {
      id: 1,
      name: "Corporate Website for ZMF LLC (UAE Accounting Firm)",
      excerpt: "A business-oriented website designed to enhance brand visibility, showcase services, and streamline client communication.",
      isLive: true,
      isPrivate: true,
      liveUrl: "https://zmfllc.com",
      githubUrl: "https://github.com/example/zmfllc-website",
      technologies: ["Next.js", "SendGrid", "Office365", "Vercel", "Railway", "DNS Management"],
      images: ["/images/zmf-landing.png", "/images/zmf-services-1.png","/images/zmf-services-2.png", "/images/zmf-contact.png", "/images/zmf-meeting.png"],
      description: "A responsive website developed for ZMF LLC, a UAE-based accounting firm, aimed at marketing their services, offering a contact form and meeting scheduler integrated with Office365 via SendGrid. The project included DNS management, and the deployment of the frontend on Vercel and backend on Railway."
    },
    
    {
      id: 2,
      name: "DevOps-Centric MERN Stack Application with ML Integration",
      excerpt: "A full-stack application showcasing DevOps practices, CI/CD pipeline, and cloud deployment.",
      isLive: false,
      isPrivate: true,
      liveUrl: "https://DegreeQuest.stude-074.uk",
      githubUrl: "https://github.com/example/mern-ml-integration",
      technologies: ["MERN", "DevOps", "CI/CD", "Google Cloud Platform", "GitHub Actions"],
      images: ["/images/dq-arc.png", "/images/dq-pm2.png", "/images/dq-nginx.png", "/images/dq-cicd.png", "/images/dq-cloudf.png", "/images/dq-ssl.png", "/images/dq-actions.png"],
      description: "A comprehensive MERN stack application with a strong focus on DevOps practices, showcasing CI/CD integration and cloud deployment."
    },
    {
      id: 3,
      name: "Different Portfolio Website with CI/CD",
      excerpt: "A project demonstrating DevOps practices through the development and deployment of a full-stack portfolio website.",
      isLive: false,
      isPrivate: true,
      liveUrl: "https://portfolio.example.com",
      githubUrl: "https://github.com/example/portfolio-website",
      technologies: ["Next.js", "Flask", "Kubernetes", "GitHub Actions"],
      images: ["/port1.png", "/port2.png", "/port3.png", "/port4.png", "/port5.png", "/port6.png", "/port7.png", "/port8.png", "/portactions.png"],
      description: "This project showcases expertise in modern DevOps practices through the development of a full-stack portfolio website."
    },
    {
      id: 4,
      name: "E-Commerce Application Deployment on AKS with Helm",
      excerpt: "A project demonstrating the deployment of a microservices-based e-commerce application on Azure Kubernetes Service (AKS) using Helm.",
      isLive: false,
      isPrivate: true,
      liveUrl: "https://ecommerce.example.com",
      githubUrl: "https://github.com/example/ecommerce-aks",
      technologies: ["Kubernetes", "Helm", "Azure", "Microservices"],
      images: ["/3tier1.png", "/3tier2.png"],
      description: "An e-commerce application deployed on AKS, showcasing microservices architecture and cloud deployment techniques."
    },
    {
      id: 5,
      name: "Arcane - Dynamic Blog Site",
      excerpt: "A dynamic blog web application developed using PHP and Laravel, showcasing cloud deployment and security practices.",
      isLive: false,
      isPrivate: true,
      liveUrl: "https://arcane-blog.example.com",
      githubUrl: "https://github.com/example/arcane-blog",
      technologies: ["PHP", "Laravel", "Docker", "Google Cloud Platform"],
      images: ["/S1.png", "/S4.png", "/S7.png"],
      description: "A dynamic blog application emphasizing modern web development and cloud deployment practices with a focus on security."
    },
    {
      id: 6,
      name: "Visitor Counter with Azure Function and API Management",
      excerpt: "A project demonstrating the implementation of a DevOps pipeline using various Azure services.",
      isLive: false,
      isPrivate: true,
      liveUrl: "https://visitor-counter.example.com",
      githubUrl: "https://github.com/example/visitor-counter",
      technologies: ["Azure Functions", "API Management", "Python", "Storage"],
      images: ["/1.png", "/2.png", "/3.png", "/4.png", "/5.png", "/5-1.png", "/5-2.png", "/6.png", "/7.png"],
      description: "An Azure Function project that implements a visitor counter, showcasing the practical application of DevOps principles."
    },
    {
      id: 7,
      name: "VM Backup Solution with AMIs and Rclone",
      excerpt: "A comprehensive solution for VM backups using AMIs, S3, and Google Drive.",
      isLive: false,
      isPrivate: true,
      liveUrl: "https://vmbackup.example.com",
      githubUrl: "https://github.com/example/vm-backup-solution",
      technologies: ["AWS", "EC2", "S3", "Rclone", "Google Drive"],
      images: ["/backup1.png", "/backup2.png", "/backup3.png"],
      description: "This project involves creating AMIs of virtual machines to meet backup requirements for clients. The AMIs are stored in an S3 bucket, and Rclone is configured to transfer backups from S3 to Google Drive, ensuring a reliable and efficient backup solution."
    }
    
    
  ];

  return (
    <div className="w-full max-w-6xl mx-auto px-4 py-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {projects.map((project) => (
          <div
            key={project.id}
            className="group relative bg-black/20 backdrop-blur-sm rounded-lg p-6 border border-gray-800 hover:border-gray-700 transition-all duration-300 hover:scale-[1.02]"
          >
            {/* Project Header */}
            <div className="flex justify-between items-start gap-4 mb-2">
              <h3 className="text-xl font-bold text-gray-100 leading-tight">
                {project.name}
              </h3>
              
              {/* <Link
              to={`/project/${project.id}/gallery`}
              // className="flex items-center gap-2 px-4 py-2 bg-purple-600 hover:bg-purple-700 rounded-md transition-all duration-300 text-white"
            >

              <button
                className="p-2 rounded-full bg-gray-800/50 hover:bg-gray-700/50 transition-colors duration-300 flex-shrink-0"
                onClick={() => setActiveProject(activeProject === project.id ? null : project.id)}
                aria-label={activeProject === project.id ? "Collapse details" : "Expand details"}
                >
                <ChevronRight 
                  className={`w-5 h-5 transition-transform duration-300 ${
                    activeProject === project.id ? 'rotate-90' : ''
                  }`}
                  />
              </button>
              </Link> */}
            </div>
            
            {/* Project Excerpt */}
            <p className="text-gray-400 mb-4 line-clamp-2">
              {project.excerpt}
            </p>

            {/* Technologies */}
            <div className="flex flex-wrap gap-2 mb-4">
              {project.technologies.map((tech) => (
                <span
                  key={tech}
                  className="px-3 py-1 text-sm bg-gray-800/50 rounded-full text-gray-300 hover:bg-gray-700/50 transition-colors duration-300"
                >
                  {tech}
                </span>
              ))}
            </div>

            {/* Action Buttons */}
            <div className="flex flex-wrap gap-3 mb-6">
              {project.isLive ? (
                <button 
                  className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-md transition-colors duration-300 text-white"
                  onClick={() => project.liveUrl && window.open(project.liveUrl, '_blank')}
                  aria-label="Open live demo"
                >
                  <span>Live Demo</span>
                  <ExternalLink className="w-4 h-4" />
                </button>
              ) : (
                <button 
                  className="flex items-center gap-2 px-4 py-2 bg-gray-700/50 rounded-md text-gray-300 cursor-not-allowed"
                  title="Not live at the moment"
                  disabled
                >
                  <span>Live Demo</span>
                  <ExternalLink className="w-4 h-4" />
                </button>
              )}


              {project.isPrivate ? (
                <button 
                  className="flex items-center gap-2 px-4 py-2 bg-gray-700/50 rounded-md text-gray-300 cursor-not-allowed"
                  title="Private Repository"
                  disabled
                >
                  <Github className="w-4 h-4" />
                <span>Code</span>
              </button>
              ) : (
                <button 
                className="flex items-center gap-2 px-4 py-2 bg-gray-800 hover:bg-gray-700 rounded-md transition-colors duration-300 text-white"
                onClick={() => window.open(project.githubUrl, '_blank')}
                aria-label="View source code"
                >
                  <Github className="w-4 h-4" />
                <span>Code</span>
                  </button>
              )}
              
              {/* <button 
                className="flex items-center gap-2 px-4 py-2 bg-gray-800 hover:bg-gray-700 rounded-md transition-colors duration-300 text-white"
                onClick={() => window.open(project.githubUrl, '_blank')}
                aria-label="View source code"
              >
                <Github className="w-4 h-4" />
                <span>Code</span>
              </button> */}
            </div>

            {/* Image Carousel */}
            <Link
              to={`/project/${project.id}/gallery`}
              className="flex items-center gap-2 px-4 py-2 bg-purple-600 hover:bg-purple-700 rounded-md transition-all duration-300 text-white"
            >
              <BadgeInfoIcon className="w-4 h-4" />
              <span>View Details</span>
            </Link>

            {/* Expanded Details */}
            {/* {activeProject === project.id && (
              <div className="mt-4 pt-4 border-t border-gray-800 animate-fadeIn">
                <h4 className="font-semibold mb-2 text-gray-200">Project Details</h4>
                <p className="text-gray-400">
                  {project.description}
                </p>
              </div>
            )} */}
          </div>
        ))}
      </div>
    </div>
  );
};


// return (
//   <div className="w-full max-w-6xl mx-auto px-4 py-8">
//     <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//       {projects.map((project) => (
//         <div
//           key={project.id}
//           className="group relative bg-black/20 backdrop-blur-sm rounded-lg p-6 border border-gray-800 hover:border-gray-700 transition-all duration-300 hover:scale-[1.02]"
//         >
//           {/* Project Header */}
//           <div className="flex justify-between items-start gap-4 mb-2">
//             <h3 className="text-xl font-bold text-gray-100 leading-tight">
//               {project.name}
//             </h3>
            
//             <button
//               className="p-2 rounded-full bg-gray-800/50 hover:bg-gray-700/50 transition-colors duration-300 flex-shrink-0"
//               onClick={() => setActiveProject(activeProject === project.id ? null : project.id)}
//               aria-label={activeProject === project.id ? "Collapse details" : "Expand details"}
//             >
//               <ChevronRight 
//                 className={`w-5 h-5 transition-transform duration-300 ${
//                   activeProject === project.id ? 'rotate-90' : ''
//                 }`}
//               />
//             </button>
//           </div>
          
//           {/* Project Excerpt */}
//           <p className="text-gray-400 mb-4 line-clamp-2">
//             {project.excerpt}
//           </p>

//           {/* Technologies */}
//           <div className="flex flex-wrap gap-2 mb-4">
//             {project.technologies.map((tech) => (
//               <span
//                 key={tech}
//                 className="px-3 py-1 text-sm bg-gray-800/50 rounded-full text-gray-300 hover:bg-gray-700/50 transition-colors duration-300"
//               >
//                 {tech}
//               </span>
//             ))}
//           </div>

//           {/* Action Buttons */}
//           <div className="flex flex-wrap gap-3 mb-6">
//             {project.isLive ? (
//               <button 
//                 className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-md transition-colors duration-300 text-white"
//                 onClick={() => project.liveUrl && window.open(project.liveUrl, '_blank')}
//                 aria-label="Open live demo"
//               >
//                 <span>Live Demo</span>
//                 <ExternalLink className="w-4 h-4" />
//               </button>
//             ) : (
//               <button 
//                 className="flex items-center gap-2 px-4 py-2 bg-gray-700/50 rounded-md text-gray-300 cursor-not-allowed"
//                 title="Not live at the moment"
//                 disabled
//               >
//                 <span>Live Demo</span>
//                 <ExternalLink className="w-4 h-4" />
//               </button>
//             )}
            
//             <button 
//               className="flex items-center gap-2 px-4 py-2 bg-gray-800 hover:bg-gray-700 rounded-md transition-colors duration-300 text-white"
//               onClick={() => window.open(project.githubUrl, '_blank')}
//               aria-label="View source code"
//             >
//               <Github className="w-4 h-4" />
//               <span>Code</span>
//             </button>
//           </div>

//           {/* Image Gallery - Using the imported component */}
//           <ImageGallery 
//             images={project.images} 
//             projectName={project.name} 
//           />

//           {/* Expanded Details */}
//           {activeProject === project.id && (
//             <div className="mt-4 pt-4 border-t border-gray-800 animate-fadeIn">
//               <h4 className="font-semibold mb-2 text-gray-200">Project Details</h4>
//               <p className="text-gray-400">
//                 {project.description}
//               </p>
//             </div>
//           )}
//         </div>
//       ))}
//     </div>
//   </div>
// );
// };

export default ProjectShowcase;